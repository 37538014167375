$primary: #ff1a06; /* MAIN COLOR */
$secondary: #00b7b7; /* SECONDARY COLOR */
$tertiary: #31eaea;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway:800');

.flash {
	display:none;
}

h1,h2,h3 {
	font-family: 'Raleway', sans-serif;
}
p {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.3em;
}
/** NAV START **/
nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;
	
	.navbar-nav {

	    > li > a {
			text-align: center;
			height: 80px;
			display: flex;
			align-items: center;


			@media (max-width: 767px) {
			    height: 28px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: #777;
				  	outline: 0;		
		    	}
		    }

			&:focus {
			  background: transparent;
			  color: #777;
			  outline: 0;
			}
		}
	}
}


.navbar-right {
	margin-top: 0px;
}


.navbar-toggle {
	margin-top: 12px;
}

/** LOGIN FORM **/
@mixin btn1 {
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}


@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}


.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	input#username, input#password {
		@media (max-width: 1024px) {
			font-size: 16px;
		}
	}
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


input#username {
	margin-bottom: 20px;
}


/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $secondary;
	color: $footerLinks;
	p {
		@media (max-width: 1024px) {
			font-size: 14px;
		}
	}
	a {
		color: $footerLinks;
		white-space: nowrap;
		@media (max-width: 1024px) {
			font-size: 14px;
		}

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}
}


input#username, input#password {
	width: 100%;
}


@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 225px;
	margin-top: 15px;
}


@media(max-width: 767px) {
	.logo {
		max-width: 150px;
	}

    .navbar-header a {
        float: left;
    } 
}

.banner {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	position: relative;
	height: 650px;
	overflow: hidden;
	background-position: 50% 20%;
	@media (max-width: 1199px) {
		height: 550px;
	}
	@media (max-width: 991px) {
		height: 500px;
	}
	@media (max-width: 767px) {
		height: 300px;
	}
}

.love {
	position: absolute;
	bottom: 2%;
	left: 50%;
	transform: translateX(-50%);
	max-width: 600px;
	width: 100%;
	@media (max-width: 1199px) {
		max-width: 400px;
	}
	@media (max-width: 991px) and (orientation: landscape) {
		max-width: 300px;
	}
}

.sideImg1 {
	background: url(../img/sideImg1.jpg) no-repeat;
	position: relative;
	background-position: 50% 20%;
	padding: 300px 0;
	p {
		display: none;
		color: white;
		@media (max-width: 991px) {
			font-size: 2em;
			display: block;
		}
	}
	@media (max-width: 991px) {
		padding: 170px 0;
		background: linear-gradient(
			rgba($primary, 0.5),
			rgba($secondary, 0.5)
			),
		url(../img/sideImg1.jpg) no-repeat;
		background-position: 50% 20%;
	}
}
.sideImg2 {
	background: url(../img/sideImg2.jpg) no-repeat;
	position: relative;
	background-position: 50% 50%;
}
.sideImg3 {
	background: url(../img/sideImg3.jpg) no-repeat;
	position: relative;
	background-position: 50% 50%;
}
.sideImg {
	background-size: cover;
	height: 600px;
	@media (max-width: 991px) {
		height: auto;
		padding: 130px 0;
	}
}
.row {
	position: relative;
}
.outerTxtBox1 {
	width: 100%;
	position: absolute;
	right: -80%;
	top: 20%;
	@media (max-width: 991px) {
		position: static;
		background: rgba(white, 0.7);
	}
	h1.title {
		color: $secondary;
		font-size: 4em;
		@media (max-width: 1299px) {
			font-size: 3.5em;
		}
		@media (max-width: 991px) {
			font-size: 3em;
			padding-top: 10px;
			text-align: center;

		}
		@media (max-width: 767px) {
			font-size: 2em;
		}
	}
.txtBox1 {
	background: white;
	padding: 40px 15px;
	@media (max-width: 991px) {
		background: none;
		padding: 10px 15px;
	}
}
}
.outerTxtBox2 {
	width: 50%;
	position: absolute;
	right: 80%;
	top: 28%;
	width: 100%;
	z-index: 2;
	@media (max-width: 991px) {
		position: static;
		background: rgba(white, 0.7);
	}
	h1.title {
		color: $primary;
		font-size: 4em;
		@media (max-width: 1299px) {
			font-size: 3.5em;
		}
		@media (max-width: 991px) {
			font-size: 3em;
			text-align: center;
			padding-top: 10px;
			color: $secondary;
		}
		@media (max-width: 767px) {
			font-size: 2em;
		}
	}
.txtBox2 {
	background: white;
	padding: 40px 15px;
	@media (max-width: 991px) {
		background: none;
		padding: 10px 15px;
	}
}
}


h1.title2 {
	font-size: 5em;
	color: $tertiary;
	@media (max-width: 1299px) {
			font-size: 3.5em;
		}
		@media (max-width: 991px) {
			font-size: 3em;
			text-align: center;
		}
		@media (max-width: 767px) {
			font-size: 2em;
	}
}

.bg {
	background: $secondary;
}
.bg2 {
	background: $primary;
	p {
		color: white;
	}
}
.form {
	max-width: 500px;
	display: block;
	margin: 0 auto;
}

.padTopBtm {
	padding: 50px 15px;
}